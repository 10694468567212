
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex';
import SalesOrder from "@/types/salesorder";
import Card from 'primevue/card';
import ChangeHistoryDataTable from '@/components/UI/ChangeHistoryDataTable.vue';

export default defineComponent({
  name: "Change History",
  components: {
    Card,
    ChangeHistoryDataTable,
  },
  computed: {
    ...mapGetters({
      getActiveOrderTab: "salesInquiry/getActiveOrderTab",
    }),
  },

  data: () => ({
    currentId: "",
    currentOrder: {} as SalesOrder,
    changesList: [] as any[],
  }),
  async created() {
    if (!this.$attrs.orderId) {
      this.$router.push("/sales/orders")
    }
    let id = this.$attrs.orderId as string;
    this.currentOrder = await this.getActiveOrderTab(id);
    this.currentId = id

    this.changesList = this.currentOrder.change_date_items;
  },
})
