
import { defineComponent } from "vue";
import { FilterMatchMode, FilterOperator } from "primevue/api";

import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";

export default defineComponent({
  name: "ChangeHistoryDataTable",
  components: {
    // DataTable,
    // Column,
    // InputText,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  emits: ['afterFilter'],
  data() {
    return {
      filters: {
        changed_by: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        approved_by: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
      },
    };
  },
  computed: {
    getChangeHistoryItems() {
      return this.items.map((item: any) => ({...item, change_text: item.change_text?.replaceAll('^', '<br>'), change_notes: item.change_notes?.replaceAll('^', '<br>')}))
    },
  },
  methods: {
    handleFilter(event: any) {
      this.$emit('afterFilter', event)
    }
  }
})

